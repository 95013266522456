import logo from '../../logo.svg';
import { useTranslation, Trans } from 'react-i18next';
import TranslationSelectorComponent from '../translation-selector-component';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import appData from '../../data/global';
import './navbar-component.css';

const navigationOptions = [
    {
        name: 'catalog',
        linkTo: '../catalog',
        captionKey: 'Static_Labels.Catalog'
    }, {
        name: 'orderCompleted',
        linkTo: '../orderCompleted',
        captionKey: 'Static_Labels.OrderHistory'
    },
    {
        name: 'profile',
        linkTo: '../profile',
        captionKey: 'Static_Labels.Profile'
    },
    {
        name: 'lms',
        linkTo: '',
        captionKey: 'Static_Labels.Lms'
    }
]

const NavbarComponent = (inOptions) => {
    const { t } = useTranslation();

    const [options, setOptions] = React.useState(inOptions);
    appData.setCartItemState(useState(appData.cartItemCount));

    const onLocationChange = (event) => {
        appData.setCulture(event.target.value);
    }

    return (
        <div className="nav-bar" role="banner">
            <a href="#main" class="skip-link">Skip to main content</a>
            <Row className="nav-left-options">
                <Col xs="auto">
                    <img
                        alt="logo"
                        src={appData.storeData.logoUrl !== '' ? appData.storeData.logoUrl : logo}
                        className="branding-icon" />
                </Col>
                <Col xs="auto">
                    <div className="nav-options">
                        {
                            options && options.options.map(val => {
                                var tempOption = navigationOptions.find(a => a.name === val);
                                return [
                                    <Link to={tempOption.linkTo} className="nav-option nav-left-option">
                                        <Trans i18nKey={tempOption.captionKey} />
                                    </Link>
                                ]
                            })
                        }
                    </div>
                </Col>
            </Row>
            <Row className="nav-right-options">
                <Col xs="auto">
                    <i className="nav-bi bi-search"></i>
                </Col>
                <Col xs="auto">
                    <Link title={t('Static_Labels.Cart')} to={`../cart`} className="nav-option" aria-label={t('Static_Label.GoToCart')}>
                        <i className="nav-bi bi-cart2"></i>
                        {appData.cartItemCount > 0 && (<span className="cart-item-count">{appData.cartItemCount}</span>)}
                    </Link>
                </Col>
                <Col xs="auto">
                    <TranslationSelectorComponent state={appData.storeData} onChange={onLocationChange} />
                </Col>
                <Col xs="auto" />
                <Col xs="auto">
                    <Link to={`../login`} className="nav-option top6"><Trans i18nKey="Static_Labels.Login/Register" /></Link>
                </Col>
            </Row>
        </div>
    );
}

export default NavbarComponent;