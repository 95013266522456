import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Select = ({ className, label, value, options, onChange }) => {
    return (
        <label>
            {label}
            <select className={className} value={value} onChange={onChange} name="select" title="select">
                {options.map((option) => (
                    <option value={option.value} key={option.key}>{option.label}</option>
                ))}
            </select>
        </label>
    );
};

const SortSelectorComponent = ({sortMode, onSortChange}) => {
    const { t, i18n } = useTranslation();

    const options = [
        { label: t("Static_Labels.Most Recent"), value: 'Most Recent', key: 'most_recent' },
        { label: t("Static_Labels.Sort Title Asc"), value: 'Title Asc', key: 'title_asc' },
        { label: t("Static_Labels.Sort Title Desc"), value: 'Title Desc', key: 'title_desc' },
        { label: t("Static_Labels.Sort Price Asc"), value: 'Price Asc', key: 'price_asc' },
        { label: t("Static_Labels.Sort Price Desc"), value: 'Price Desc', key: 'price_desc' },
    ];

    const [value, setValue] = React.useState(sortMode);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onSortChange) onSortChange(event.target.value);
    };

    return (
        <div>
            <Select
                className="mt"
                options={options}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};

export default SortSelectorComponent;