import axios from "axios";
import serviceUtil from './service-util';
import appData from '../data/global.js'

class SessionService {

    constructor() {
        const service = axios.create({
            baseURL: process.env.REACT_APP_COMMERCE_API_URL,
        });
        this.service = service;
    }

    postInitSession() {
        return this.service.post('/init-session?sessionId=' + appData.getSessionId(), null, appData.getInitSessionHeaders())
        .then(function (response) {
            var data = response.data;
            appData.sessionId = data.sessionId;
            appData.cartId = data.cartId;
            appData.userData = data.userData;
            appData.userToken = data.authToken;
            console.log(appData);
            return data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }

    getCartItems() {
        return this.service.get('/cart-items', appData.getCommonHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }

    removeCartItem(itemId) {
        return this.service.delete('/cart-item?itemId=' + itemId, appData.getCommonHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }

    updateItemSeatCount(itemId, seatCount) {
        const payload = { itemId, seatCount };
        return this.service.post('/cart-item', payload, appData.getCommonHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }

    addItemToCart(item) {
      return this.service.put('/item', item, appData.getCommonHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }

    addSessionItemToCart(sessionItem) {
      return this.service.put('/item-session', sessionItem, appData.getCommonHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }
}

export default new SessionService();