import './error-component.css';

const ErrorBox = ({ error }) => {
    return (
        <div className="error" role="main">
            <header className="errorTitle" class="sr-only" role="heading" aria-level="1">Failed loading Application</header>
            <div className="errorBox">
                <div class="errorMessage"><b>Reason:</b> {error.message}</div>
                <div class="errorFunction"><b>Function:</b> {error.functionName}</div>
                <div class="errorLocation"><b>Stack:</b> <span>{error.location}</span></div>
            </div>
            <footer style={{ "display": "none" }}>App failed to load</footer>
        </div>
    );
}

export default ErrorBox;