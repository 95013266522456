import React, { useState } from 'react';

class AppData {

    constructor() {
        this.storeId = null;
        this.sessionId = null;
        this.cartId = null;
        this.orderId = null;
        this.userData = null;
        this.userToken = null;
        this.currentPage = null;
        this.culture = null;
        this.storeData = null;
        this.itemId = null;
        this.onCultureChanged = null;
        this.cartItemCount = 0;
        this.setCartItemCount = null;
    }

    setCartItemState(state) {
      this.cartItemCount = state[0];
      this.setCartItemCount = state[1];
    }

    getInitSessionHeaders() {
        return {
            headers: {
                'X-StoreId': this.storeId
            }
        }
    }

    getCommonHeaders() {
        return {
            headers: {
                'X-StoreId': this.storeId,
                'X-SessionId': this.sessionId
            }
        }
    }

    getSessionId() {
        return this.sessionId && this.sessionId !== '' ? this.sessionId : '';
    }

    initCurrentPage(path) {
        if (path && path !== '') {
            const split = path.split('/');
            this.culture = split.length > 1 ? split[1] : '';
            this.currentPage = split.length > 2 ? split[2] : 'catalog';
            this.itemId = split.length > 3 ? split[3] : null;
        }
    }

    increaseCartItems() {
        this.setCartItemCount(prevState => prevState + 1);
    }

    decreaseCartItems() {
        this.setCartItemCount(prevState => prevState - 1);
    }

    setCulture(culture) {
        if (culture !== this.culture) {
            this.culture = culture;
            if (this.onCultureChanged) {
                this.onCultureChanged();
            }
        }
    }

    getCurrentPage() {
        const tempArray = ['', this.culture, this.currentPage ];
        if (this.itemId && this.itemId !== '') tempArray.push(this.itemId);
        return tempArray.join('/');
    }

    setStoreData(data) {
        this.storeId = data.storeId;
        this.storeData = data;
    }

    setSessionData(data) {
        this.userData = data.userData;
        this.userToken = data.authToken;
        this.sessionId = data.sessionId;
        this.cartId = data.cartId;
    }
}

export default new AppData();