import './register-login-form-component.css'
import { useState, useEffect, useRef } from 'react';
import RegisterComponent from '../register/register-component';
import LoginComponent from '../login/login-component';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const RegisterLoginFormComponent = ({ onTabClick }) => {
   
    return (
        <>
            <Row>
                <Tabs
                    defaultActiveKey="register"
                    className="mb-3"
                    fill
                    justify
                    onSelect={e => onTabClick(e)}
                >
                    <Tab eventKey="register" title="Register">
                        <RegisterComponent></RegisterComponent>
                    </Tab>
                    <Tab eventKey="login" title="Log In">
                        <LoginComponent></LoginComponent>
                    </Tab>
                </Tabs>
            </Row>
        </>
    );
}

export default RegisterLoginFormComponent;