import axios from "axios";
import DOMPurify from 'dompurify'
import { useEffect, useState, useRef } from 'react';
import appData from '../../data/global';
import './banner-component.css';

const Banner = () => {
    const loading = useRef(true);
    const [htmlText, setHtmlText] = useState('');

    useEffect(() => {
        if (loading.current) {
            loading.current = false;
            if (appData && appData.storeData && appData.storeData.customHeaderUrl) {
                axios.create({})
                    .get(appData.storeData.customHeaderUrl)
                    .then(response => {
                        setHtmlText(DOMPurify.sanitize(response.data));
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    })

    return (
        <div className="banner">
            <div dangerouslySetInnerHTML={{ __html: htmlText }} />
        </div>
    )
}

export default Banner;