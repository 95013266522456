
class ServiceUtil {
    getResponseError = (err) => {
        if (err.response && err.response.data) {
            return err.response.data;
        }

        return {
            message: err.response
                ? 'Unexpected response from backend service: ' + err.response
                : 'Failed connecting to backend service',
            functionName: 'WebApp',
            location: err.toString()
        };
    }
}

export default new ServiceUtil();