import axios from "axios";
import serviceUtil from './service-util';
import appData from '../data/global';

class UserService {
    constructor() {
        const service = axios.create({
            baseURL: process.env.REACT_APP_STORE_API_URL
        });
        this.service = service;
    }

    validateEmailAddress(email) {
        return this.service.get('/validate-email', { params: { emailAddress: email } }, appData.getCommonHeaders())
            .then(function (response) {
                console.log(response.data);
                return response.data;
            })
            .catch(function (error) {
                throw serviceUtil.getResponseError(error)
            })
    }

    register() {
        return this.service.get('/register', appData.getCommonHeaders())
            .then(function (response) {
                console.log(response);
                return response.data;
            })
            .catch(function (error) {
                throw serviceUtil.getResponseError(error);
            })
    }

    login() {
        return this.service.get('/login', appData.getCommonHeaders())
            .then(function (response) {
                console.log(response);
                return response.data;
            })
            .catch(function (error) {
                throw serviceUtil.getResponseError(error);
            })
    }

    proceedToPayment() {
        return this.service.get('/proceed-to-payment', appData.getCommonHeaders())
            .then(function (response) {
                console.log(response);
                return response.data;
            })
            .catch(function (error) {
                throw serviceUtil.getResponseError(error);
            })
    }
}

export default new UserService();