import React, { useRef, useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner"
import catalogService from '../../service/catalog-service';
import sessionService from '../../service/session-service';
import appData from '../../data/global';
import './item-details-component.css'


const currencySymbols = {
  'USD': '$',
  'EUR': '€'
}

const ItemDetailsComponent = () => {
    const navigate = useNavigate();
    const firstLoad = useRef(true);
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});
    const [isOnCart, setIsOnCart] = useState({});

    const closePopup = () => {
        appData.itemId = undefined;
        navigate('..');
    }

    const onChildItemClick = (childItem) => {
      const payload = {
          itemId: item.itemId,
          iltSessionId: childItem.iltSessionId,
          type: 'IltSession',
          title: `${item.title} - ${childItem.name}`,
          summaryDescription: item.summaryDescription,
          imageUrl: '',
          languages: item.languages,
          priceAmount: item.priceAmount,
          priceCurrency: item.priceCurrency,
          location: childItem.location.name,
          sessionDate: formatDates(childItem.dateStart, childItem.dateFinish, childItem.timeZone),
      };
      sessionService.addSessionItemToCart(payload)
        .then(response => {
          appData.increaseCartItems();
          childItem.isOnCart = true;
          setIsOnCart(true);
        })
        .catch(error => {
          console.log(error);
          alert(error.message);
        })
    }

    const onItemClick = () => {
       const payload = {
          itemId: item.itemId,
          type: item.type,
          title: item.title,
          summaryDescription: item.summaryDescription,
          fullDescription: item.fullDescription,
          languages: item.languages,
          imageUrl: item.imageUrl,
          priceAmount: item.priceAmount,
          priceCurrency: item.priceCurrency,
        };
      sessionService.addItemToCart(payload)
        .then(response => {
          appData.increaseCartItems();
          item.isOnCart = true;
          setIsOnCart(true);
        })
        .catch(error => {
          console.log(error);
          alert(error.message);
        })
    }

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            catalogService.getItemDetails(appData.itemId)
                .then(response => {
                    setItem(response);
                    setIsOnCart(response.isOnCart);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    alert(error.message);
                    setLoading(false);
                })
        }
    });

    const formatDates = (start, end, timezone) => {
      var dateStart = new Date(start);
      var dateEnd = new Date(end);
      var dateString = dateStart.toLocaleString();
      if (dateStart.getFullYear() === dateEnd.getFullYear()
        && dateStart.getMonth() === dateEnd.getMonth()
        && dateStart.getDay() === dateEnd.getDay()) {
        dateString += ' - ' + dateEnd.toLocaleTimeString();
      } else {
        dateString += ' - ' + dateEnd.toLocaleString();
      }
      return `${dateString} (${timezone})`;
    }

    const SessionItem = ({sessionItem, price}) => {
        const childClick = () => {
          onChildItemClick(sessionItem);
        };
        return (
            <div className="detail-child-item">
                <div className="detail-child-content">
                    <div className="detail-child-title">{sessionItem.name}</div>
                    <div className="detail-child-descr">{formatDates(sessionItem.dateStart, sessionItem.dateFinish, sessionItem.timeZone)}</div>
                    <div className="detail-child-descr">{sessionItem.location.name}</div>
                </div>
                <div className="detail-add-cart" >
                    <AddToCartButton item={sessionItem} currency={item.priceCurrency} price={item.priceAmount} click={childClick} />
                </div>
            </div>
        );
    }

    const Sessions = ({sessions}) => {
        if (!sessions || sessions.length === 0) {
            return (<></>);
        }
        return (
            <div className="detail-children-box">
                {sessions.map(item => [<SessionItem sessionItem={item} key={item.iltSessionId} />])}
            </div>
        )
    }

    const CourseItem = ({courseItem}) => {
        return (
            <div className="detail-child-item">
                <div>
                    <div className="detail-child-title">{courseItem.title}</div>
                    <div className="detail-child-descr">{courseItem.summaryDescription}</div>
                </div>
            </div>
        );
    }

    const ModuleItem = ({moduleItem}) => {
        return (
            <div className="detail-child-item">
                <div>
                    <div className="detail-child-title">{moduleItem.title}</div>
                    <div className="detail-child-descr">{moduleItem.summaryDescription}</div>
                </div>
            </div>
        );
    }

    const Courses = ({courses}) => {
        if (!courses || courses.length === 0) {
            return (<></>);
        }
        return (
            <div className="detail-children-box">
                {courses.map(item => [<CourseItem courseItem={item} key={item.courseId} />])}
            </div>
        )
    }

    const Modules = ({modules}) => {
        if (!modules || modules.length === 0) {
            return (<></>);
        }
        return (
            <div className="detail-children-box">
                {modules.map(item => [<ModuleItem moduleItem={item} key={item.moduleId} />])}
            </div>
        )
    }

    const LangItem = ({language}) => {
       return (
            <div className="detail-lang">
                <i className="bi bi-translate"></i>
                <span className="detail-lang-text">{language}</span>
            </div>
        )
    }

    const ChildrenCount = () => {
      let icon = 'det-bi bi-course-icon';
      let list = item.courses;
      let key = 'Static_Labels.Courses';
      if (item.type === 'Course') {
        if (item.sessions && item.sessions.length > 0) {
          icon = 'det-bi bi-session-icon';
          list = item.sessions;
          key = 'Static_Labels.Sessions';
        } else {
          icon = 'det-bi bi-module-icon';
          list = item.modules;
          key = 'Static_Labels.Modules';
        }
      }

      return (
          <div className="detail-type">
              <i className={icon}></i>
              <span>{(list && list.length) || 0} <Trans i18nKey={key} /></span>
          </div>
      )
    }

    const AddToCartButton = ({item, currency, price, click}) => {
        return isOnCart ? (
            <button className="add-to-cart-button-disabled" disabled="true">
                 <i className="nav-bi bi-added-to-cart"></i>
                 <span>
                    <Trans i18nKey="Static_Labels.AddedToCart" />
                 </span>
            </button>
        ) : (
            <button className="add-to-cart-button" onClick={click}>
                <i className="nav-bi bi-add-to-cart"></i>
                <span style={{ "margin-left": "12px", "padding-bottom": "6px", "position": "relative", "top": "-5px" }}>
                    {currencySymbols[currency]} {(price||0).toFixed(2)} | <Trans i18nKey="Static_Labels.AddToCart" />
                </span>
            </button>
        )
    }

    const TitleBar = () => (
        <div className="detail-pop-title">
           <span>{item.title}</span>
           <button className="detail-close-button" onClick={closePopup}>X</button>
        </div>
    )

    const LanguagesBar = () => (
        <div className="detail-lang-section">
            <div className="detail-type">
                <i className={item.type === 'Course' ? "bi bi-course-icon" :  "bi bi-training-icon"}></i>
                <span className="detail-type-text"><Trans i18nKey={"Static_Labels." + item.type} /></span>
            </div>
            { item.languages && item.languages.map(lang => <LangItem key={lang} language={lang} />) }
        </div>
    )

    const StarPoints = () => (
        <div className="detail-type">
            <i className="det-bi bi-star"></i>
            <span className="detail-type-text">{(item.stars || 4.3).toFixed(1)} ({(item.opinionCount || 7)})</span>
        </div>
    )

    const LastUpdatedItem = () => (
        <div className="detail-type">
            <i className="det-bi bi-updated-date"></i>
            <span className="detail-type-text">{item.lastUpdated || "Last Updated on Feb. 23rd, 2024"}</span>
        </div>
    )

    const StatisticsBar = () => (
        <div className="detail-stats">
            <ChildrenCount />
            <StarPoints />
            <LastUpdatedItem />
        </div>
    )

    const ItemDescription = () => (
        <div className="detail-desc-section">
            <div className="detail-description">{item.fullDescription}</div>
            <div className="detail-description2">
                <b><Trans i18nKey="Static_Labels.RelatedSkills" /></b> {item.relatedSkills || "[SKILL TITLE], [SKILL TITLE]"}
            </div>
            <div className="detail-description2">
                <b><Trans i18nKey="Static_Labels.CreatedBy" /></b> {item.createdBy || "Fistname Lastname"}
            </div>
        </div>
    )

    const ItemDetails = () => (
        <div className="detail-pop-split-data">
           <LanguagesBar />
           <StatisticsBar />
           <ItemDescription />
           {(item.type !== 'Course' || !item.sessions || item.sessions.length === 0) && (
           <div className="detail-add-cart-left" >
               <AddToCartButton item={item} currency={item.priceCurrency} price={item.priceAmount} click={onItemClick} />
           </div>
           )}
        </div>
    )

    const DetailSplitArea = () => (
        <div className="detail-pop-split">
            <ItemDetails />
            <img alt="card detail" className="detail-image" src={item.imageUrl || "https://s3.amazonaws.com/images.seroundtable.com/google-rainbow-texture-1491566442.jpg"} />
        </div>
    )

    return loading ? (
        <div className="detail-shadow">
            <div className="detail-popup">
                <div className="text-center py-5">
                    <Spinner animation="border" />
                </div>
            </div>
        </div>
    ) : (
        <>
            <div className="detail-shadow">
                <div className="detail-popup">
                    <TitleBar />
                    <DetailSplitArea />
                    <Sessions sessions={item.sessions} />
                    <Courses courses={item.courses} />
                    <Modules modules={item.modules} />
                </div>
            </div>
         </>
    );
}

export default ItemDetailsComponent;