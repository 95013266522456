import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import AppRoot from './routes/app-root';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import CatalogComponent from './components/catalog/catalog-component';
import ItemDetailsComponent from './components/catalog/item-details-component';
import CartComponent from './components/cart/cart-component';
import CheckoutComponent from './components/checkout/checkout-component';
import OrderCompleteComponent from './components/checkout/order-complete-component';
import LoginComponent from './components/login/login-component';

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppRoot />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/:culture",
                element: <App />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: "catalog",
                        element: <CatalogComponent />,
                        children: [
                            {
                                path: ":itemId",
                                element: <ItemDetailsComponent />,
                                errorElement: <ErrorPage />,
                            }
                        ]
                    },
                    {
                        path: "cart",
                        element: <CartComponent />,
                    },
                    {
                        path: "login",
                        element: <LoginComponent />,
                    },
                    {
                        path: "checkout",
                        element: <CheckoutComponent />,
                    },
                    {
                        path: "orderComplete",
                        element: <OrderCompleteComponent />,
                    },
                ],
            }
         ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Suspense>
            <RouterProvider router={router} />
        </Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
