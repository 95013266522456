import axios from "axios";
import serviceUtil from './service-util';
import appData from '../data/global';

class CatalogService {
    constructor() {
        const service = axios.create({
            baseURL: process.env.REACT_APP_STORE_API_URL,
        });
        this.service = service;
    }

    getCatalogData(start, size, sort, filters) {
        const nFilters = {
            topics: filters.topics ? filters.topics.map(item => item.id) : null,
            types: filters.contentTypes ? filters.contentTypes.map(item => item.id) : null
        };
        return this.service.post('/get-items', {
            pageStart: start,
            pageSize: size,
            sortBy: sort,
            filters: nFilters
        }, appData.getCommonHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }

    getFilterOptions(domain) {
        return this.service
            .get('/filter-options', appData.getCommonHeaders())
            .then(response => response.data)
            .catch(error => { throw serviceUtil.getResponseError(error) })
    }

    getItemDetails(itemId) {
        return this.service.get(`/item-details?itemId=${itemId}`, appData.getCommonHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }
}

export default new CatalogService();
