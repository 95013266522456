import React, { useEffect, useState, useRef } from 'react';
import catalogService from '../../service/catalog-service';
import Spinner from "react-bootstrap/Spinner"
import Form from 'react-bootstrap/Form';
import { Trans } from 'react-i18next';
import ErrorBox from '../error-component';
import './filter-component.css';

const CheckboxList = ({ title, list, onCheckItem, level }) => {

    const CheckBoxes = ({list, level}) => {
        var margin = 10 * level;
        if (list && list.length > 0) {
            return list.map(item => [
                <div style={{ marginLeft: margin }} key={item.name}>
                    <Form.Check className="filter-item"
                         aria-label={item.name}
                         label={item.name}
                         checked={item.checked}
                         key={item.name}
                         onChange={evt => { evt.preventDefault(); item.checked = evt.target.checked; onCheckItem(item, evt.target.checked); return true;} }
                   />
                   <CheckBoxes list={item.subTopics} level={level + 1} />
                </div>
            ]);
        }
    }

    return list && list.length > 0 && (
        <div className="filter-group">
            <fieldset>
                <div className="filter-group-title">
                    <legend>
                        <Trans i18nKey={"Static_Labels." + title}/>
                    </legend>
                </div>
                <CheckBoxes list={list} level={0} />
            </fieldset>
        </div>
    );
}

const FilterComponent = ({ onChangeFilters }) => {
    const firstLoad = useRef(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [contentTypes, setContentTypes] = useState([]);
    const [topics, setTopics] = useState([]);
    const selectedFilters = useRef({ contentTypes: [], topics: []});

    const checkContentTypeItem = (item, checked) => {
        const currentContentTypes = selectedFilters.current.contentTypes;
        if (checked) {
            currentContentTypes.push(item);
        } else {
            const index = currentContentTypes.indexOf(item);
            currentContentTypes.splice(index, 1);
        }

        if (onChangeFilters) {
            onChangeFilters(selectedFilters.current)
        }
    }

    const checkTopic = (item, checked) => {
        const currentTopics = selectedFilters.current.topics;
        if (checked) {
            currentTopics.push(item);
        } else {
            const index = currentTopics.indexOf(item);
            currentTopics.splice(index, 1);
        }

        if (onChangeFilters) {
            onChangeFilters(selectedFilters.current)
        }
    }

    const handleFilterOptionsResponse = (response) => {
        setContentTypes(response.contentTypes);
        setTopics(response.searchTopics);
        setLoading(false);
        const tempContentTypes = [];
        Object.assign(tempContentTypes, response.contentTypes);
        selectedFilters.current.contentTypes = tempContentTypes;

        if (onChangeFilters) {
            onChangeFilters(selectedFilters.current)
        }
    }

    const initialize = () => {
        if (firstLoad.current) {
            firstLoad.current = false;
            catalogService.getFilterOptions()
                .then(handleFilterOptionsResponse)
                .catch(setError);
        }
    }

    useEffect(initialize, []);

    if (error && error !== '') {
        return (
            <ErrorBox error={error} />
        );
    }

    return loading ? (
        <div className="filters-section">
            <div className="text-center py-5">
                <Spinner animation="border" />
            </div>
        </div>
    ) :  (
        <div className="filters-section">
             <div className="filter-title">
                 <Trans i18nKey="Static_Labels.Filter" />
             </div>
             <CheckboxList title="ContentType" list={contentTypes} onCheckItem={checkContentTypeItem} />
             <CheckboxList title="Topics" list={topics} onCheckItem={checkTopic} />
        </div>
    );
}

export default FilterComponent;
