import { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './register-component.css'
import userService from '../../service/user-service';

const RegisterComponent = () => {
    const [registerFirstName, setRegisterFirstName] = useState("");
    const [registerLastName, setRegisterLastName] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerCompany, setRegisterCompany] = useState("");
    //need endpoint create to actually register the user and manage password security
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerPasswordError, setRegisterPasswordError] = useState("");
    const [registerPasswordHasError, setRegisterPasswordHasError] = useState(false);
    //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;

    const ValidateEmail = async () => {
        var email = registerEmail;
        await userService.validateEmailAddress(email)
            .then(response => {
                console.log(response);
            })
            .catch(err => console.log(err))
    }

    const HandlePasswordBlur = (event) => {
        setRegisterPassword(event.target.value);
        //pattern validation
        if (!passwordRegex.test(event.target.value)) {
            setRegisterPasswordHasError(true);
            //copied from Litmos account/ChangePassword
            setRegisterPasswordError("Password must contain: 1 upper case, 1 lower case, 1 number, 1 special character, 8 characters.");
        }
    }

    const CorrectPasswordError = (event) => {
        setRegisterPassword(event.target.value);

        if (registerPasswordHasError) {
            //if user corrected error & matches now
            if (passwordRegex.test(event.target.value)) {
                setRegisterPasswordHasError(false);
                setRegisterPasswordError("");
            }
        }
    }

    return (
        <div className="registerComp">
            <Row>
                <Col xs="6"> 
                    <label for="registerFirstName" className="label">First Name</label>
                    <input type="text" className="form-control" id="registerFirstName" value={registerFirstName} onChange={e => setRegisterFirstName(e.target.value)} ></input>
                </Col>
                <Col xs="6">
                    <label for="registerLastName">Last Name</label>
                    <input type="text" className="form-control" id="registerLastName" value={registerLastName} onChange={e => setRegisterLastName(e.target.value)} ></input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label for="registerEmail">Email</label>
                    <input type="email" className="form-control" id="registerEmail" onBlur={ValidateEmail} value={registerEmail} onChange={e => setRegisterEmail(e.target.value) } ></input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label for="registerCompany">Company</label>
                    <input type="text" className="form-control" id="registerCompany" value={registerCompany} onChange={e => setRegisterCompany(e.target.value)}></input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label for="registerPassword">Password</label>
                    <input type="password" className="form-control" id="registerPassword" onBlur={HandlePasswordBlur} value={registerPassword} onChange={ CorrectPasswordError }></input>
                </Col>
            </Row>
            <Row>
                <Col>
                    { registerPasswordHasError ?
                        <div id="registerPasswordRulesDynam">
                            <br/>
                            <br/>
                            { registerPasswordError }
                        </div> :
                        <div></div>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default RegisterComponent;