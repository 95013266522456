import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SeatsCounter from './seats-counter-component';
import sessionService from '../../service/session-service';
import './cart-item-component.css';

const currencySymbols = {
  'USD': '$',
  'EUR': '€'
}

const CartItem = ({item, onSeatsUpdated, onRemoveClick}) => {
    const [seatCount, setSeatCount] = useState(item.seatCount);
    const DEACTIVATE_FOR_MVP = process.env.REACT_APP_DEACTIVATE_FOR_MVP === "true";

    const seatsUpdated = (newValue) => {
        sessionService.updateItemSeatCount(item.itemId, newValue)
            .then(response => {
                item.seatCount = newValue;
                setSeatCount(newValue);
                if (onSeatsUpdated) onSeatsUpdated(item, newValue);
            })
            .catch(err => {
                console.log(err);
                alert(err.message);
            });
    };

    const deleteItem = () => {
        if (onRemoveClick) onRemoveClick(item);
    };

    const LangItem = ({language}) => {
       return (
            <div className="item-lang">
                <img alt="langImage" className="lang-image" src="/language.svg" />
                <span className="lang-text">{language}</span>
            </div>
        )
    }

    const LangItems = ({langItems}) => {
        return (
            <div className="item-langs">
            { langItems && langItems.map(lang => <LangItem key={lang} language={lang} />) }
            </div>
        );
    }

    return (
        <div className="cart-item">
            <div className="item-image-box">
                <img alt="cartImage" className="item-image" src={item.imageUrl || 'https://s3.amazonaws.com/images.seroundtable.com/google-rainbow-texture-1491566442.jpg'} />
                <LangItems langItems={item.languages} />
            </div>
            <div className="item-texts">
                <div className="item-title">{item.title}</div>
                <div className="item-desc">{item.summaryDescription}</div>
                { DEACTIVATE_FOR_MVP ? <div/> :
                <div className="seat-selector">
                    <div className="seat-sel-text"><Trans i18nKey="Static_Labels.Seats" /></div>
                    <SeatsCounter value={seatCount} onChanged={seatsUpdated} />
                </div>
                }
            </div>
            <div className="cart-item-price">
                <div className="item-price-value">
                    <span className="seats-span"><Trans i18nKey="Static_Labels.Seats" />: {seatCount} x </span>
                    <span>{currencySymbols[item.priceCurrency]} {item.priceAmount}</span>
                </div>
                <a className="remove-link" onClick={deleteItem}><Trans i18nKey="Static_Labels.Remove" /></a>
            </div>
        </div>
    )
}


export default CartItem;