import React, { useState } from 'react';
import './seats-counter-component.css'

const SeatsCounter = ({value, onChanged}) => {

    const incrementCounter = () => {
        onChanged(value + 1);
    }

    const decrementCounter = () => {
        if (value > 1) {
            onChanged(value - 1);
        }
    }

    return (
        <div className="seat-sel-comp">
            <button onClick={decrementCounter} className="dec-button">-</button>
            <div className="counter-value">{value}</div>
            <button onClick={incrementCounter} className="inc-button">+</button>
        </div>
    );
}

export default SeatsCounter;