
const OrderCompleteComponent = () => {
  
    return (
        <div>
            <p>This is my empty order complete page</p>
        </div>
    );
}

export default OrderCompleteComponent;