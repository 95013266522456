import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner"
import NavbarComponent from '../navigation/navbar-component';
import { Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './checkout-component.css'
import RegisterLoginFormComponent from '../register-login-form/register-login-form-component';
import appData from '../../data/global';
import userService from '../../service/user-service';
//import CartItems from '../cart/cart-component';
import ItemComponent from '../catalog/item-component';

const CheckoutComponent = () => {
    //show cart items 
    const [cartHasItems, setCartHasItems] = useState(false);
    const location = useLocation();
    const { cartItems } = location.state;
    const [cartTotal, setCartTotal] = useState(0);

    //show or hide register/login based on loggedIn status
    const loggedIn = false;
    const [activeTab, setActiveTab] = useState("register");
    const [loading, setLoading] = useState(true);
    const [paymentProvider, setPaymentProvider] = useState("Paypal");
    const [hasPaymentProvider, setHasPaymentProvider] = useState(false);

    useEffect(() => {
        console.log("from: ", cartItems);
        if (cartItems != null || cartItems.length == 0) { //do i need check for length == 0?
            setCartHasItems(true);
            FindCartSum();
        }
        setLoading(false);
        GetPaymentProviderName();
    });

    const handleSelect = (key) => {
        setActiveTab(key);
    }

    const FindCartSum = () => {
        const sum = cartItems.reduce((sum, { priceAmount }) => sum + priceAmount, 0);
        setCartTotal(sum);
    }

    const GetPaymentProviderName = () => {
        setPaymentProvider(appData.storeData.paymentProviderName);
        setHasPaymentProvider(true);
    }

    //const ProceedToPayment = async() => {
    //    // console.log("amount: ", total);
    //    await userService.proceedToPayment()
    //        .then(response => {
    //            console.log(response);
    //        })
    //        .catch(err => console.log(err))
    //};

    const RedirectToPaymentProvider = () => {
        userService.proceedToPayment()
            .then(response => {
                console.log(response);
            })
            .catch(err => console.log(err))
    }

    const LoginOrRegister = async () => {
        //based on active tab
        console.log("activeTab: ", activeTab);
        if (activeTab == "register") {
            console.log("registered");
            // register
            //await userService.register()
            //    .then(response => {
            //        console.log(response);
            //        ProceedToPayment();
            //    })
            //    .catch(err => console.log(err))
        }
        else if (activeTab == "login") {
            console.log("loggedIn");
            // login
            //await userService.login()
            //    .then(response => {
            //        console.log(response);
            //        ProceedToPayment();
            //    })
            //    .catch(err => console.log(err))
        }
    }

    return (
        <>
            <NavbarComponent options={['catalog']} />
            {loading ? (
                <div className="checkout">
                    <div className="text-center py-5">
                        <Spinner animation="border" />
                    </div>
                </div>
            ) : (
                    <div className="checkout">
                        <Row>
                            <Col>
                                <div className="checkout-path">
                                    <Trans i18nKey="Static_Labels.Catalog" /> &gt; <Trans i18nKey="Static_Labels.Cart" /> &gt; <Trans i18nKey="Static_Labels.Checkout"/>
                                </div>
                                {loggedIn ? (<></>) : (<RegisterLoginFormComponent onTabClick={handleSelect}></RegisterLoginFormComponent>)}
                                <Row>
                                    <Col>
                                        <input type="checkbox" id="termsCheck"></input>
                                        <label for="termsCheck">
                                            I have read and understand the Terms and Conditions
                                        </label>
                                    </Col>
                                </Row>
                                {hasPaymentProvider ? (
                                    <div id="redirectDynam">
                                        <p className="inline">You will be redirected to {paymentProvider} for payment.</p>
                                        <button type="button" onClick={() => RedirectToPaymentProvider()} className="btn btn-primary inline">Proceed to payment</button>
                                    </div>
                                ) : (
                                    <div>
                                        <p>Oops, we need to set up a payment provider.</p>
                                    </div>
                                )}
                                
                            </Col>

                            <Col>
                                <div className="checkout-path">
                                    <span>Your Cart</span>
                                </div>
                                <div className="line"></div>
                                {cartHasItems && cartItems.length > 0 ?
                                    (
                                        <>
                                            {cartItems.map(item => [<ItemComponent type="tile" item={item} key={item.itemId} />])}
                                            <div className="line"></div>
                                            <h3><Trans i18nKey="Static_Labels.Total" /> ${cartTotal}</h3>
                                        </>
                                    ) : (
                                        <>
                                            <p>Your cart is empty.</p>
                                        </>
                                    )}
                            </Col>
                        </Row>
                </div>
            )}

        </>
    );
}

export default CheckoutComponent;