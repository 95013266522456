import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './login-component.css'

const LoginComponent = () => {

    return (
        <div className="loginComp">
            <Row>
                <Col>
                    <label for="loginEmail">Email</label>
                    <input type="email" className="form-control" id="loginEmail" placeholder=""></input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label for="loginPassword">Password</label>
                    <input type="password" className="form-control" id="loginPassword" placeholder=""></input>
                </Col>
            </Row>
        </div>
    );
}

export default LoginComponent;