import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TranslationSelectorComponent = ({ state, onChange } ) => {
    const { t } = useTranslation();
    const [cultures, setCultures] = useState([]); 

    useEffect(() => {
        setCultures(state.cultures);
    }, [state]);

    const languagesEnum = {
        "es-ES": "Languages.Spanish", 
        "en-US": "Languages.English", 
        "fr-fr": "Languages.French (France)", 
        "fr-ca": "Languages.French (Canada)",
        "cn": "Languages.Chinese (Simplified)"
    }

    return (
        <div>
            <i className="nav-bi bi-translate"></i>
            <label for="languageSelect" className="lang-selector">
                <select value={state.culture} onChange={onChange} name="languageSelect" title="languageSelect" id="languageSelect">
                {
                    cultures != null ? cultures.map((item) => <option label={t(languagesEnum[item])} value={item} key={item}>{item} </option>) : ""
                }
                </select>
            </label>
        </div>
    );
};

export default TranslationSelectorComponent;