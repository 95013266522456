import React from 'react';
import './item-component.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation, Trans } from 'react-i18next';


const currencySymbols = {
  'USD': '$',
  'EUR': '€',
  'GBP': '£'
}

const ItemComponent = ({ type, item, onClick }) => {

    const getItemImagePath = () => {

      return 'https://s3.amazonaws.com/images.seroundtable.com/google-rainbow-texture-1491566442.jpg';
    }

   const LangItem = ({language}) => {
       return (
            <div className="catalog-item-lang">
                <i class="bi bi-translate"></i>
                <span className="catalog-lang-text">{language}</span>
            </div>
        )
    }

    const LangItems = ({languages}) => {
        return (
            <div className="catalog-item-langs">
            { languages && languages.length > 0 && (<LangItem key={languages[0]} language={languages[0]} />) }
            </div>
        );
    }
    if (type == "card") {
        return (
            <>
                <div className="catalog-item-card" onClick={onClick}>
                    <img className="catalog-item-card-image" src={getItemImagePath()} alt={item.title} />
                    <div className="catalog-item-title">{item.title}</div>
                    <div className="catalog-item-descr">{item.summaryDescription}</div>
                    <div className="catalog-item-price-box">
                        <LangItems languages={item.languages} />
                        <div className="catalog-item-price">{currencySymbols[item.priceCurrency]}{item.priceAmount.toFixed(2)}</div>
                    </div>
                </div>
            </>
        );
    }
    else if (type == "tile") {
        return (
            <>
                <div className="catalog-item-tile">
                    <Row>
                        <Col xs="3">
                            <img className="catalog-item-tile-image" src={getItemImagePath()} alt="Item" />
                            <LangItems languages={item.languages} />
                        </Col>
                        <Col xs="6">
                            <Row>
                                { item.title }
                            </Row>
                            <Row>
                                { item.summaryDescription }
                            </Row>
                        </Col>
                        <Col xs="3">
                            <Row>
                                {currencySymbols[item.priceCurrency]} {item.priceAmount.toFixed(2)}
                            </Row>
                            <Row>
                                <Trans i18nKey="Static_Labels.Remove" />
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="line"></div>
            </>
        )
    }
}

export default ItemComponent;