import React, { useRef, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation, Trans } from 'react-i18next';
import { Outlet, useNavigate } from "react-router-dom";
import SortSelectorComponent from './sort-selector-component';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavbarComponent from '../navigation/navbar-component';
import FilterComponent from './filter-component.js';
import Banner from '../banner/banner-component';
import appData from '../../data/global';
import ItemComponent from './item-component';
import catalogService from '../../service/catalog-service';
import './catalog-component.css';
import Spinner from "react-bootstrap/Spinner"

const CatalogComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const domain = "dev-5-2";
    const [loading, setLoading] = useState(false);
    const [catalog, setCatalog] = useState([]);
    const [filters, setFilters] = useState({});
    const [filterList, setFilterList] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageStart, setPageStart] = useState(0);
    const [sortMode, setSortMode] = useState('');

    const GetCatalogData = async (pageStart, pageSize, mode, filters) => {
        setLoading(true);
        catalogService.getCatalogData(pageStart, pageSize, mode, filters)
            .then(response => {
                setCatalog(response);
                setLoading(false);
            })
            .catch(err => console.log(err))
    }

    const filtersChanged = (filters) => {
       const newFilters = [];
       setFilters(filters);
       filters.contentTypes.map(item => newFilters.push(item.name));
       filters.topics.map(item => newFilters.push(item.name));
       GetCatalogData(pageStart, pageSize, sortMode, filters);
       setFilterList(newFilters);
    }

    const handleSortChange = (mode) => {
        GetCatalogData(pageStart, pageSize, mode, filters);
        setSortMode(mode);
    }

    const itemClick = (item) => {
        appData.itemId = item.itemId;
        navigate(item.itemId);
    }

    const CatalogItems = () => {
        if (loading) {
            return (
                <div className="text-center py-5">
                    <Spinner animation="border" />
                </div>
            );
        }

        return catalog && catalog.map(item => [
           <ItemComponent type="card" item={ item } onClick={() => itemClick(item)} key={item.itemId} />
        ]);
    }

    const Filters = ({items}) => {
        return items && items.map(item => [
           <div className="catalog-selected-filter" key={item}>
             <span>{item}</span>
             <span className="remove-filter">X</span>
           </div>
        ]);
    }

    const Catalog = () => {
        return (
            <div className="catalog-section">
                <div className="catalog-search-bar">
                    <div className="catalog-sel-filters">
                        <Filters items={filterList} />
                    </div>
                </div>
                <div className="catalog-title-bar" role="heading" aria-level="1">
                    <div className="results-count">
                        <span>Results ({ catalog.length }) </span>
                    </div>
                    <div className="catalog-app-icon">
                       <i class="nav-bi bi-app"></i>
                    </div>
                    <div className="catalog-date-icon">
                      <i class="nav-bi bi-calendar-date"></i>
                    </div>
                    <div className="catalog-sort">
                        <SortSelectorComponent
                            logo={null}
                            width="30"
                            height="30"
                            text={t("Static_Labels.Store")}
                            sortMode={sortMode}
                            onSortChange={handleSortChange}
                        />
                    </div>
                </div>
                <div className="catalog-items-box">
                    <div className="catalog-items">
                        <CatalogItems />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <NavbarComponent options={['catalog']} />
            <div role="main" id="main" tabindex="-1">
                <Banner />
                <div className="catalog-title">
                    <span><Trans i18nKey="Static_Labels.Catalog" /></span>
                </div>
                <div className="catalog">
                    <FilterComponent onChangeFilters={filtersChanged} />
                    <Catalog />
                </div>
            </div>
            <Outlet />
        </>
    );
}

export default CatalogComponent;