import { useState, useEffect, useRef } from 'react';
import Spinner from "react-bootstrap/Spinner"
import NavbarComponent from '../navigation/navbar-component';
import CartItem from './cart-item-component';
import { useTranslation, Trans } from 'react-i18next';
import sessionService from '../../service/session-service';
import appData from '../../data/global';
import './cart-component.css'
import { Link } from "react-router-dom";

const CartComponent = () => {
    const { t } = useTranslation();

    const firstLoad = useRef(true);
    const [loading, setLoading] = useState(true);
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);

    const LoadCartItems = () => {
        setLoading(true);
        sessionService.getCartItems()
            .then(response => {
                setCartItems(response);
                setTotal(calculateTotals(response));
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                alert(err.message);
                setLoading(false);
            })
    }

    const seatsUpdated = (item) => {
        setTotal(calculateTotals(cartItems));
    }

    const removeItem = (item) => {
        const index = cartItems.indexOf(item);
        if (index >= 0) {
            sessionService.removeCartItem(item.itemId)
                .then(response => {
                    if (response.wasDeleted) {
                        cartItems.splice(index, 1);
                        setCartItems(cartItems);
                        appData.decreaseCartItems();
                        setTotal(calculateTotals(cartItems));
                    } else {
                        alert(response.errorMessage);
                    }
                })
                .catch(err => {
                    console.log(err);
                    alert(err.message);
                });
        }
    }

    const calculateTotals = (cartItems) => {
        var total = 0;
        if (cartItems) {
            cartItems.map(item => total += item.seatCount * item.priceAmount);
        }
        return total.toFixed(2);
    }

    const CartItems = ({items}) => {
        return (
             <div className="cart-items">
             {
                items && items.length > 0
                  ? items.map(item => <CartItem key={item.itemId} item={item} onSeatsUpdated={seatsUpdated} onRemoveClick={removeItem} />)
                  : (
                     <div className="empty-cart"><Trans i18nKey="Static_Labels.EmptyCart" /></div>
                  )
             }
             </div>
        );
    }

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            LoadCartItems();
        }
    });

    return (
        <>
            <NavbarComponent options={['catalog']} />
            { loading ? (
                <div className="cart">
                    <div className="text-center py-5">
                        <Spinner animation="border" />
                    </div>
                </div>
            ) : (
                <div className="cart">
                    <div className="cart-path">
                        <Trans i18nKey="Static_Labels.Catalog" /> &gt; <Trans i18nKey="Static_Labels.Cart" />
                    </div>
                    <div className="cart-title">
                        <Trans i18nKey="Static_Labels.Cart" />
                    </div>
                    <div className="cart-box">
                        <CartItems items={cartItems} />
                    <div className="cart-totals">
                        <div className="cart-total"><Trans i18nKey="Static_Labels.Total" /> {total}</div>
                    </div>
                    <div className="button-area">
                        {/*<button className="checkout-button"><Link to={`../checkout`} className="nav-option"><Trans i18nKey="Static_Labels.Checkout" /></Link></button>*/}
                        <button className="checkout-button"><Link to="../checkout" state={{ cartItems: cartItems }} className="nav-option"><Trans i18nKey="Static_Labels.Checkout" /></Link></button>
                    </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CartComponent;