import axios from "axios";
import serviceUtil from './service-util';
import appData from '../data/global.js';

class StoreService {
    constructor() {
        const service = axios.create({
            baseURL: process.env.REACT_APP_STORE_API_URL
        });
        this.service = service;
    }

    getStoreData() {
        return this.service.get('/store-data', {
            params: {
                culture: appData.culture
            }
        })
            .then(function (response) {
            console.log("store Data: ", response);
            appData.storeId = response.data.storeId;
            return response.data;
        })
        .catch(function (error) {
            throw serviceUtil.getResponseError(error)
        })
    }
}

export default new StoreService();