import logo from '../logo.svg';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from "react-router-dom";
import sessionService from '../service/session-service';
import storeService from '../service/store-service';
import Footer from '../components/footer/footer-component';
import React, { useState, useEffect } from 'react';
import appData from '../data/global';
import Spinner from "react-bootstrap/Spinner"
import './App.css';

const App = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const CreateSession = async () => {
        await sessionService.postInitSession()
            .then(response => {
                appData.setSessionData(response);
                setLoading(false);
            })
            .catch(err => console.log(err))
    }

    const GetStoreData = async () => {
        await storeService.getStoreData()
            .then(response => {
                appData.setStoreData(response);
                i18n.changeLanguage(response.culture);
                CreateSession();
            })
            .catch(err => console.log(err))
    }

    return loading ? (
        <div className="App">
            <div className="text-center py-5">
                <Spinner animation="border" />
            </div>
        </div>
    ) : (
        <>
            <link rel="stylesheet" type="text/css" href={appData.storeData.customCssUrl} />
            <div className="App">
                <Outlet />
                <Footer />
            </div>
        </>
    );
}

export default App;