import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner"
import sessionService from '../service/session-service';
import storeService from '../service/store-service';
import appData from '../data/global';
import ErrorBox from '../components/error-component';
import './App.css';

var firstLoad = true;

const AppRoot = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleLocationChange = (event) => {
        setLoading(true);
        GetStoreData();
    }

    const setLocaleChangeHandler = () => {
        if (!appData.onCultureChanged) {
            appData.onCultureChanged = handleLocationChange;
        }
    }

    const CreateSession = () => {
        sessionService.postInitSession()
            .then(response => {
                appData.setSessionData(response);
                setLoading(false);
            })
            .catch(err => setError(err))
    }

    const GetStoreData = () => {
        storeService.getStoreData()
            .then(response => {
                appData.setStoreData(response);
                appData.setCulture(response.culture);
                i18n.changeLanguage(response.culture);
                navigate(appData.getCurrentPage());
                setLocaleChangeHandler();
                CreateSession();
            })
            .catch(err => setError(err))
    }

    const ReadRoutePath = () => {
        var search = window.location.search;
        appData.initCurrentPage(window.location.pathname);
        var startIndex = search.indexOf('sessionId=');
        if (startIndex >= 0) {
            // recover the sessionId from the path
            startIndex += 10;
            const endIndex = search.indexOf('&', startIndex);
            appData.sessionId = search.substring(startIndex, endIndex < 0 ? undefined : endIndex)
        }
    }

    useEffect(() => {
        if (firstLoad) {
            firstLoad = false;
            ReadRoutePath();
            GetStoreData();
        }
    });

    if (error && error !== '') {
        return (
            <ErrorBox error={error} />
        );
    }

    return loading ? (
        <div className="App">
            <div className="text-center py-5">
                <Spinner animation="border" />
            </div>
        </div>
    ) : (
        <Outlet />
    );
}

export default AppRoot;